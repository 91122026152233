import React from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Row,
  Col,
  Select,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Logo from "../../assets/GRINTECH_Reverse_01_full-removebg2.png";
import { Login as LoginService, LoginTeknisi } from "../../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6LdaUoceAAAAANzcjQLSA-x8eFZpWeKIjYLWxkrH";
const DELAY = 1500;

const Login = (props) => {
  const [loading, setLoading] = React.useState(false);
  const authState = useSelector((state) => state);
  const dispatch = useDispatch();

  const [load, setLoad] = React.useState(false);
  const [callback, setCallback] = React.useState("not fired");
  const [expired, setExpired] = React.useState("false");
  const [value, setValue] = React.useState(null);

  const _chaptcharef = React.useRef();

  React.useState(() => {
    setTimeout(() => {
      setLoad(true);
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", _chaptcharef);
  }, []);

  const asyncScriptOnLoad = () => {
    setCallback("called!!!");
    console.log("scriptLoad - reCaptcha Ref-", _chaptcharef);
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    // if (values.role == "Admin") {
    LoginService(
      { email: values.email, password: values.password },
      setLoading,
      (cb) => {
        // console.log(cb, "CB");
        dispatch(cb);
        // window.location.reload();
      }
    );
    // } else {
    //   LoginTeknisi(
    //     { email: values.email, password: values.password },
    //     setLoading,
    //     (cb) => {
    //       // console.log(cb, "CB");
    //       dispatch(cb);
    //     }
    //   );
    // }
  };

  React.useEffect(() => {
    if (
      authState.auth.logined &&
      !loading &&
      window.localStorage.getItem("token")
    ) {
      window.location.replace("/");
    }
  }, [authState, loading]);

  return (
    <div>
      <Row>
        <Col
          xl={24}
          sm={24}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "240px 0",
            height: "100%",
          }}
        >
          <Form
            name="normal_login"
            className="login-form"
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: "36px",
                fontSize: "24px",
              }}
            >
              {/* <Typography.Title level={3}>Login</Typography.Title> */}
              <img
                src={Logo}
                alt="Logo"
                style={{ position: "relative", width: "80%" }}
              />
            </div>
            {/* <Form.Item
              name="role"
              initialValue={"Admin"}
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Select placeholder="Login Role">
                <Select.Option value="Admin">Admin</Select.Option>
                <Select.Option value="Technician">Technician</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              name="email"
              // initialValue={localStorage.getItem("username")}
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              // initialValue={localStorage.getItem("password")}
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              {load && (
                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  ref={_chaptcharef}
                  sitekey={SITE_KEY}
                  onChange={(value) => {
                    console.log("Chaptcha value:", value);
                    setValue(value);
                    if (value === null) setExpired("true");
                  }}
                  asyncScriptOnLoad={asyncScriptOnLoad}
                />
              )}
            </Form.Item>
            <Form.Item>
              <a href="/forgot-password">Forgot Password ?</a>
              <Button
                disabled={value ? false : true}
                loading={loading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ marginTop: "12px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
