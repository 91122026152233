import { message } from "antd";
import api from "config/axiosConfig";
import WEIOTSLOGO from "../assets/logo1.png";

const dataDummy = {
  username: "admin",
  password: "123456",
  name: "Admin WASP",
  phone: "08819671476",
  email: "admin@gmail.com",
  photo: WEIOTSLOGO,
};

const Login = async (data, setLoading, cb) => {
  setLoading(true);
  cb({
    type: "AUTH WAITING",
  });
  try {
    let _api = await api({
      url: "/users/admin/login",
      method: "POST",
      data: {
        email: data.email,
        password: data.password,
      },
    });
    setLoading(false);
    // message.success(_api.data.message);
    cb({
      type: "AUTH SUCCESS",
      data: {
        token: _api.data.token,
        profile: _api.data.profile,
        role: _api.data.role,
      },
    });
    window.localStorage.setItem("token", _api.data.token);
    // console.log(_api.data, "DATA API");
    window.localStorage.setItem("role", _api.data.role);
  } catch (error) {
    let errMsg = [];
    if (error.response) {
      errMsg = [
        error.response.data.message,
        " ",
        error.response.data.responseCode,
      ];
    } else {
      errMsg = [error.message, " ", 500];
    }
    setLoading(false);

    cb({
      error: errMsg[1],
      message: errMsg[0],
      type: "AUTH ERROR",
    });

    message.error(errMsg);
    return errMsg;
  }
};

const LoginTeknisi = async (data, setLoading, cb) => {
  setLoading(true);
  cb({
    type: "AUTH WAITING",
  });
  try {
    let _api = await api({
      url: "/mobile/teknisi/login",
      method: "POST",
      data: {
        email: data.email,
        password: data.password,
      },
    });
    setLoading(false);
    message.success(_api.data.message);
    cb({
      type: "AUTH SUCCESS",
      data: {
        token: _api.data.token,
        profile: _api.data.profile,
      },
    });
    window.localStorage.setItem("token", _api.data.token);
  } catch (error) {
    let errMsg = [];
    if (error.response) {
      errMsg = [
        error.response.data.message,
        " ",
        error.response.data.responseCode,
      ];
    } else {
      errMsg = [error.message, " ", 500];
    }
    setLoading(false);

    cb({
      error: errMsg[1],
      message: errMsg[0],
      type: "AUTH ERROR",
    });

    message.error(errMsg);
    return errMsg;
  }
};

const _apiAccount = {
  regVerify: async ({ setLoading = (e) => e, data, params, base64, pw }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/verify/account/" + base64 + "/" + pw,
        method: "POST",
        data,
        params,
      });

      setLoading(false);
      message.success(_api.data.message);
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      setLoading(false);

      message.error(errMsg);
      return errMsg;
    }
  },
  ChangePassword: async ({ setLoading = (e) => e, data, params }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/admin/change/password",
        method: "POST",
        data,
        params,
      });

      setLoading(false);

      message.success(_api.data.message);
      setTimeout(() => {
        window.location.assign("/");
      }, 1000);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      setLoading(false);
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      setLoading(false);

      message.error(errMsg);
      return errMsg;
    }
  },
  ForgotPassord: async ({ setLoading = (e) => e, data, params }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/admin/forget/password",
        method: "POST",
        data,
        params,
      });
      setLoading(false);

      message.success(_api.data.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      setLoading(false);

      message.error(errMsg);
      return errMsg;
    }
  },
  ForgotVerify: async ({ setLoading = (e) => e, data, params }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/admin/forget/password/confirm",
        method: "POST",
        data,
        params,
      });
      setLoading(false);

      message.success(_api.data.message);
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      setLoading(false);

      message.error(errMsg);
      return errMsg;
    }
  },
};

const MenuList = {
  getMenu: async ({ setLoading = (e) => e, data, params }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/menu/privilege/list",
        params,
        data,
      });

      setLoading(false);
      return _api.data;
    } catch (error) {
      let errMsg = [];

      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      setLoading(false);

      return errMsg;
    }
  },
};

export { Login, _apiAccount, LoginTeknisi, MenuList };
