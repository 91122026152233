import React from "react";
import {
  Layout,
  Typography,
  Dropdown,
  Menu,
  message,
  Button,
  Avatar,
  Space,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const { Header } = Layout;

const AppHeader = ({ mode, pageBreak, hide, setHide }) => {
  const authState = useSelector((state) => state);
  const dispatch = useDispatch();

  const urlData = window.location.pathname;

  React.useEffect(() => {
    if (!authState || !authState.auth.logined) {
      if (
        ![
          "/login",
          "/forgot-password",
          "/register-verify",
          "/login/",
          "/forgot-password/",
          "/register-verify/",
          "/fp-verify/",
          "/fp-verify",
          "/reg-am/",
          "/reg-am",
        ].includes(urlData)
      ) {
        window.location.replace("/login");
      }
    }
    // console.log(moment(authState.auth.expiredAt).format("LLLL"));

    if (moment(authState.auth.expiredAt).isBefore(moment())) {
      message.warn("Session Login telah berakhir");
      dispatch({ type: "AUTH OUT" });
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
    }
  }, [urlData]);

  // console.log(authState);

  //   console.log(authState, "AUTHSTATE");
  return (
    <Header
      style={{
        padding: 0,
        textAlign: "right",
        background: mode === "dark" ? undefined : "#fff",
      }}
    >
      <span
        style={{
          float: "left",
          padding: "0 12px",
          display: pageBreak ? "none" : undefined,
        }}
      >
        <Button type="text" size="large" onClick={() => setHide(!hide)}>
          {hide ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </span>
      <span
        style={{
          float: "left",
          padding: "18px 0",
        }}
      >
        {pageBreak ? (
          <Typography.Title
            level={4}
            style={{
              color: mode === "dark" ? "#fff" : undefined,
              marginLeft: "20px",
            }}
          >
            GrinTech
          </Typography.Title>
        ) : (
          <Typography.Title
            level={4}
            style={{ color: mode === "dark" ? "#fff" : undefined }}
          >
            GrinTech
          </Typography.Title>
        )}
      </span>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0">
              <a>Settings</a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="/change-password">Change Password</a>
            </Menu.Item>
            <Menu.Item key="1">
              <a
                onClick={() => {
                  dispatch({ type: "AUTH OUT" });
                  setTimeout(() => {
                    window.location.replace("/login");
                  }, 1000);
                }}
              >
                Log Out
              </a>
            </Menu.Item>
            {/* <Menu.Divider />
        <Menu.Item key="3">3rd menu item</Menu.Item> */}
          </Menu>
        }
        trigger={["click"]}
      >
        <a
          style={{ marginRight: "16px" }}
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <Space align="center">
            <div style={{ textAlign: "left" }}>
              Hi, {authState.auth?.profile?.profile?.email}
            </div>
            {/* <Avatar
              style={{ backgroundColor: "#1890ff" }}
              size="large"
              icon={<UserOutlined />}
            /> */}
          </Space>
        </a>
      </Dropdown>
    </Header>
  );
};

export default AppHeader;
