import React from "react";
import {
  BrowserRouter,
  Switch as SwitchRoute,
  Route,
  Link,
} from "react-router-dom";
import "./App.less";
import Loading from "components/loading/Loading";
import {
  Layout,
  Menu,
  BackTop,
  Divider,
  Result,
  Button,
  Spin,
  Grid,
} from "antd";
import { DashboardFilled, createFromIconfontCN } from "@ant-design/icons";
import routes from "routes";
import Login from "pages/Login/Login.jsx";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import AppHeader from "components/header/AppHeader";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "reducers/index";
import LogoWeiots from "assets/GRINTECH_Reverse_01_full-removebg2.png";
import LogoWeiots2 from "assets/logo1.png";
import RegisterVerify from "pages/RegisterVerify/RegisterVerify";
import ForgotPasswordVerify from "./pages/ForgotPassword/ForgotPasswordVerify";
import RegAmReff from "pages/RegAmReff/RegisterAm";
import { MenuList } from "actions/auth";
import moment from "moment";

const IconFont = createFromIconfontCN({
  scriptUrl: "http://at.alicdn.com/t/c/font_2831949_fppipcgssee.js",
});

const { store, persistor } = configureStore();

const { Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;
// const ProfileInfo = {
// name: "Imam Mujiono Singo",
// img: Abah,
// };

const { useBreakpoint } = Grid;

const _menulist2 = [
  {
    link: "/dashboard",
    name: "Dashboard",
    icon: "Dashboard",
  },
  {
    link: "/devices",
    name: "Hotspots",
    icon: "icon-devices",
  },
  {
    link: "/affiliate-marketing",
    name: "Affiliate Marketing",
    icon: "icon-affiliate-marketing",
  },
  {
    link: "/landlord",
    name: "Landlord",
    icon: "icon-ImgBorrowLandlord",
  },
  {
    link: "/technician",
    name: "Technician",
    icon: "icon-personnel",
  },
  {
    link: "/user-account",
    name: "User Accounts",
    icon: "icon-user-account",
  },
  {
    link: "/master-checklist",
    name: "Master Checklist",
    icon: "icon-checklistandpencil",
  },
  {
    link: "/survey",
    name: "Survey",
    icon: "icon-icons8-survey1",
  },
  {
    link: "ticket",
    name: "Ticket",
    icon: "icon-tickets",
    children: [
      {
        link: "/installation",
        name: "Installation",
        icon: "icon-installation",
      },
      {
        link: "/list-inspection",
        name: "Inspection",
        icon: "icon-PROJECT_CHECKLIST",
      },
      {
        link: "/maintenance",
        name: "Maintenance",
        icon: "icon-maintenance",
      },
    ],
  },
  {
    link: "/crypto-wallet",
    name: "Crypto Wallet",
    icon: "icon-blog_buycrypto",
  },
  {
    link: "withdraw",
    name: "Withdraw",
    icon: "icon-money-withdrawal",
    children: [
      {
        link: "/withdraw-request",
        name: "Withdraw Request",
        // icon: "icon-installation" />,
      },
      {
        link: "/withdraw-history",
        name: "Withdraw History",
        // icon: "icon-PROJECT_CHECKLIST" />,
      },
    ],
  },
];

const App = () => {
  const [path, setPath] = React.useState("/price-list");
  const [breakPointPosition, setBreakPointPosition] = React.useState(false);
  const [mode, setMode] = React.useState("light");
  const screens = useBreakpoint();

  const [collapse, setCollapse] = React.useState(false);

  const [hide, setHide] = React.useState(false);

  const [_menulist, setMenuList] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (window.localStorage.getItem("token") != null) {
      MenuList.getMenu({
        setLoading,
      })
        .then((ok) => {
          // console.log(ok.data, "DATAA");
          if (ok.responseCode == 200) {
            if (ok.role == "admin") {
              let d = ok.data || [];

              // d.push({
              //   link: "report",
              //   name: "Report",
              //   icon: "icon-analysisreport",
              //   children: [
              //     {
              //       link: "/report/daily-reward",
              //       name: "Daily Reward",
              //     },
              //     {
              //       link: "/report/installation-ticket",
              //       name: "Installation Ticket",
              //     },
              //     {
              //       link: "/report/survey",
              //       name: "Survey",
              //     },
              //   ],
              // });

              if (d.filter((val) => val.link == "/wallet-history").length > 0) {
                setMenuList(ok.data);
              } else {
                // d.push({
                //   link: "/wallet-history",
                //   name: "Wallet History",
                //   icon: "icon-history2",
                // });

                // console.log(d, "DD");
                setMenuList(d);
              }
            } else {
              setMenuList(ok.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // console.log(ProfileInfo, "PROFILE INFO");
  React.useEffect(() => {
    window.localStorage.setItem("mode", mode);
  }, [mode]);

  const urlData = window.location.pathname;

  React.useEffect(() => {
    setMode("light");
    setHide(false);
    if (urlData === "/") {
      try {
        if (window.localStorage.getItem("role").includes("teknisi")) {
          window.location.replace("/dashboard");
        } else {
          window.location.replace("/dashboard");
        }
      } catch (error) {
        console.log("NOT SIGNED");
      }
    } else {
      for (let index = 0; index < _menulist.length; index++) {
        if (urlData.includes(_menulist[index].link)) {
          setPath(_menulist[index].link);
          break;
        } else {
          setPath(urlData);
          break;
        }
      }
    }
  }, [urlData, _menulist]);

  // console.log(path, "PATH");

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <BrowserRouter>
          <SwitchRoute>
            <Route
              path="/login"
              exact={true}
              name="Login"
              component={(props) => <Login {...props} />}
            />
            <Route
              path="/forgot-password"
              exact={true}
              name="Forgot Password"
              component={(props) => <ForgotPassword {...props} />}
            />
            <Route
              path="/register-verify"
              exact={true}
              name="Register Verify"
              component={(props) => <RegisterVerify {...props} />}
            />
            <Route
              path="/fp-verify"
              exact={true}
              name="Forgot Password Verify"
              component={(props) => <ForgotPasswordVerify {...props} />}
            />
            <Route
              path="/reg-am"
              exact={true}
              name="Register Am Using Reff"
              component={(props) => <RegAmReff {...props} />}
            />
          </SwitchRoute>
          {![
            "/login",
            "/forgot-password",
            "/register-verify",
            "/login/",
            "/forgot-password/",
            "/register-verify/",
            "/fp-verify/",
            "/fp-verify",
            "/reg-am/",
            "/reg-am",
          ].includes(urlData) && (
            <Layout style={{ minHeight: "100vh" }}>
              <Sider
                collapsed={collapse}
                // collapsible
                breakpoint="lg"
                // width="280"
                hidden={hide}
                theme={mode}
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                  console.log(broken);
                  setBreakPointPosition(broken);
                }}
                onCollapse={(collapsed) => {
                  // console.log(collapsed, type);
                  setCollapse(collapsed);
                }}
                // zeroWidthTriggerStyle={{ display: "none" }}
                style={
                  breakPointPosition
                    ? { position: "fixed", height: "100%", zIndex: "100" }
                    : {}
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: collapse ? "24px 0 0 4px" : "24px 0 0 0",
                  }}
                >
                  <img
                    alt="Logo"
                    src={collapse ? LogoWeiots2 : LogoWeiots}
                    style={{
                      position: "relative",
                      width: collapse ? "50%" : "80%",
                    }}
                  />
                </div>
                <Divider />
                <Spin spinning={loading}>
                  <Menu
                    theme={mode}
                    onSelect={(info) => {
                      // console.log(info, "INFO");
                      setPath(info.key);
                    }}
                    selectedKeys={[path]}
                    mode="inline"
                  >
                    {_menulist.map((val) => {
                      if (val.children) {
                        return (
                          <SubMenu
                            key={val.link}
                            icon={
                              val.name == "Dashboard" ? (
                                <DashboardFilled />
                              ) : (
                                <IconFont type={val.icon} />
                              )
                            }
                            title={val.name}
                          >
                            {val.children.map((val2, idx2) => {
                              return (
                                <Menu.Item
                                  key={val2.link}
                                  icon={
                                    val2.icon ? (
                                      <IconFont type={val2.icon} />
                                    ) : undefined
                                  }
                                >
                                  <Link to={val2.link}>{val2.name}</Link>
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        );
                      } else {
                        return (
                          <Menu.Item
                            key={val.link}
                            icon={
                              val.name == "Dashboard" ? (
                                <DashboardFilled />
                              ) : (
                                <IconFont type={val.icon} />
                              )
                            }
                          >
                            <Link to={val.link}>{val.name}</Link>
                          </Menu.Item>
                        );
                      }
                    })}
                  </Menu>
                </Spin>
              </Sider>
              <Layout>
                <AppHeader
                  setHide={setCollapse}
                  hide={collapse}
                  mode={mode}
                  pageBreak={breakPointPosition}
                />
                <Content>
                  <div style={{ padding: screens.xs ? "0px" : "32px" }}>
                    <React.Suspense fallback={Loading()}>
                      {routes.filter(
                        (val) => val.path === window.location.pathname
                      ).length > 0 ? (
                        <SwitchRoute>
                          {routes.map((route, idx) => {
                            return route.component ? (
                              <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={(props) => (
                                  <route.component
                                    {...props}
                                    name={route.name}
                                    responsive={breakPointPosition}
                                  />
                                )}
                              />
                            ) : null;
                          })}
                        </SwitchRoute>
                      ) : (
                        <Result
                          status="404"
                          title="404"
                          subTitle="Sorry, the page you visited does not exist."
                          extra={
                            <Button
                              type="primary"
                              onClick={() => window.location.replace("/")}
                            >
                              Back Home
                            </Button>
                          }
                        />
                      )}
                    </React.Suspense>
                  </div>
                </Content>
                <Footer
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    background: mode === "dark" ? "#001529" : "#fff",
                    color: mode === "dark" ? "#fff" : "#000",
                  }}
                >
                  Copyright © GRINTECH {moment().format("YYYY")}.
                </Footer>
                <BackTop />
              </Layout>
            </Layout>
          )}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
