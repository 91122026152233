import React from "react";
import { Form, Input, Button, Typography, Row, Col, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../../assets/GRINTECH_Reverse_01_full-removebg2.png";
import { useSelector } from "react-redux";
import { _apiAccount } from "../../actions/auth";

const ForgotPassword = () => {
  const [loading, setLoading] = React.useState(false);
  const authState = useSelector((state) => state);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    _apiAccount.ForgotPassord({
      setLoading,
      data: {
        email: values.email,
      },
    });
  };

  React.useEffect(() => {
    if (authState.auth.logined && !loading) {
      setTimeout(() => {
        window.location.replace("/");
      }, 1000);
    }
  }, [authState, loading]);

  return (
    <div>
      <Row>
        <Col
          xl={24}
          sm={24}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "240px 0",
            height: "100%",
          }}
        >
          <Form
            name="normal_login"
            className="login-form"
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: "36px",
                fontSize: "24px",
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{ position: "relative", width: "80%" }}
              />
              <Typography.Title
                level={4}
                style={{ marginTop: "14px", fontWeight: "bolder" }}
              >
                Forgot Password
              </Typography.Title>
            </div>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your Email!" },
                { type: "email" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item>
              <Alert
                message="Link to reset your password will be sent to this email."
                // description="Link to reset your password will be sent to this email."
                type="info"
                showIcon
              />
            </Form.Item>
            <Form.Item>
              <a href="/login">Login ?</a>

              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ marginTop: "12px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
