// First we need to import axios.js
import axios from "axios";
import { URL } from "config/url.config";
// import { setupCache } from "axios-cache-adapter";

// const cache = setupCache({
//   maxAge: 2592000,
// });
// Next we make an 'instance' of it

const api = axios.create({
  // .. where we make our configurations
  baseURL: URL,
  // adapter: cache.adapter,

  // baseURL: LOCAL_URL
});

const urlData = window.location.pathname;

// console.log(window.location.pathname)
// Where you would set stuff like your 'Authorization' header, etc ...
if (localStorage.getItem("token")) {
  api.defaults.headers.common["Authorization"] = localStorage.getItem("token");
} else {
  if (
    ![
      "/login",
      "/forgot-password",
      "/register-verify",
      "/login/",
      "/forgot-password/",
      "/register-verify/",
      "/fp-verify/",
      "/fp-verify",
      "/reg-am/",
      "/reg-am",
    ].includes(urlData)
  ) {
    window.location.replace("/login");
  }
}

// console.log(localStorage.getItem("token"), "TOKEN");
// // Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default api;
