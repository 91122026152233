import React from "react";

const Dashboard = React.lazy(() => import("pages/Dashboard/index"));
// const PriceList = React.lazy(() => import("pages/Price List/PriceList"));
const Devices = React.lazy(() => import("pages/Devices/Devices"));
const RegisterDevice = React.lazy(() => import("pages/Devices/RegisterDevice"));
const AllAffiliate = React.lazy(() =>
  import("pages/Affiliate Marketing/AmList")
);
const Technician = React.lazy(() => import("pages/Technician/Technician"));
const AllLandlord = React.lazy(() => import("pages/Landlord/landlord"));
const AllAccount = React.lazy(() => import("pages/User Account/AllAccount"));
const RegisterUser = React.lazy(() =>
  import("pages/User Account/RegisterUser")
);
const EditDevices = React.lazy(() => import("pages/Devices/EditDevices"));
const ViewDevices = React.lazy(() => import("pages/Devices/ViewDevice"));
const EditPassword = React.lazy(() =>
  import("pages/ChangePassword/ChangePassword")
);
const EditUsers = React.lazy(() => import("pages/User Account/EditUser"));
const ViewUsers = React.lazy(() => import("pages/User Account/ViewUser"));
// const ChecklistInspection = React.lazy(() =>
//   import("pages/ChecklistInspection/ChecklistInspection")
// );
const MasterChecklistInspection = React.lazy(() =>
  import("pages/MasterChecklistInspection/MasterChecklistInspection")
);
const MasterChecklistInspectionAdd = React.lazy(() =>
  import("pages/MasterChecklistInspection/AddChecklist")
);
const MasterChecklistInspectionEdit = React.lazy(() =>
  import("pages/MasterChecklistInspection/EditChecklis")
);
const MasterInspection = React.lazy(() =>
  import("pages/MasterInspection/MasterInspection")
);
const MasterInspectionAdd = React.lazy(() =>
  import("pages/MasterInspection/AddInspection")
);
const MasterSurvey = React.lazy(() => import("pages/Survey/Survey"));
const MasterSurveyEdit = React.lazy(() => import("pages/Survey/EditSurvey"));
const MasterSurveyView = React.lazy(() => import("pages/Survey/ViewSurvey"));
const MasterSurveyAdd = React.lazy(() => import("pages/Survey/AddSurvey"));

const Installation = React.lazy(() =>
  import("pages/Installation/Installation")
);

const InstallationAdd = React.lazy(() =>
  import("pages/Installation/AddInstallation")
);

const InstallationEdit = React.lazy(() =>
  import("pages/Installation/EditInstallation")
);

const InstallationView = React.lazy(() =>
  import("pages/Installation/ViewInstallation")
);

const ChangeAddressFromView = React.lazy(() =>
  import("pages/Survey/ChangeAddress")
);
const ChangeAddressFromEdit = React.lazy(() =>
  import("pages/Survey/ChangeAddressEdit")
);
// React.lazy(() => import())

const CryptoWallet = React.lazy(() =>
  import("pages/Crypto Wallet/CryptoWallet")
);

const WithdrawRequest = React.lazy(() =>
  import("pages/Withdraw/Withdraw Request/WithdrawRequest")
);

const WithdrawHistory = React.lazy(() =>
  import("pages/Withdraw/Withdraw History/WithdrawHistory")
);

const ViewChecklist = React.lazy(() =>
  import("pages/MasterChecklistInspection/ViewChecklist")
);

const SubmitInstallase = React.lazy(() =>
  import("pages/Installation/SubmitInstallation")
);

const MinningHistory = React.lazy(() =>
  import("pages/Minning History/MinningHistory")
);

const WalletHistory = React.lazy(() =>
  import("pages/Wallet History/WalletHistoryPage")
);

const Maintenance = React.lazy(() =>
  import("pages/Maintenance/ListMaintenance")
);

const AddMaintenance = React.lazy(() =>
  import("pages/Maintenance/AddMaintenance")
);

const EditMaintenance = React.lazy(() =>
  import("pages/Maintenance/EditMaintenance")
);

const SubmitMaintenance = React.lazy(() =>
  import("pages/Maintenance/SubmitMaintenance")
);

const ViewMaintenance = React.lazy(() =>
  import("pages/Maintenance/ViewMaintenance")
);

const Surveyor = React.lazy(() => import("pages/Surveyor/Surveyor"));
const SubmitSurveyor = React.lazy(() =>
  import("pages/Surveyor/SubmitSurveyor")
);

const ViewSurveyor = React.lazy(() => import("pages/Surveyor/ViewSurveyor"));

// { path: "",  name: "", component: , exact: },
const ReportDailyReward = React.lazy(() => import("pages/Report/DailyReward"));
const ReportInstallationTicket = React.lazy(() =>
  import("pages/Report/InstallationTicket")
);
const ReportSurveyTicket = React.lazy(() =>
  import("pages/Report/SurveyTicket")
);

const routes = [
  { path: "/", name: "Dashboard", component: Dashboard, exact: true },
  { path: "/dashboard", name: "Dashboard", component: Dashboard, exact: true },
  {
    path: "/report/daily-reward",
    name: "Daily Reward",
    component: ReportDailyReward,
    exact: true,
  },
  {
    path: "/report/installation-ticket",
    name: "Installation Ticket",
    component: ReportInstallationTicket,
    exact: true,
  },
  {
    path: "/report/survey",
    name: "Survey Ticket",
    component: ReportSurveyTicket,
    exact: true,
  },
  { path: "/devices", name: "Devices", component: Devices, exact: true },
  {
    path: "/devices/register-device",
    name: "Register Devices",
    component: RegisterDevice,
    exact: true,
  },
  {
    path: "/devices/edit-device",
    name: "Edit Register Devices",
    component: EditDevices,
    exact: true,
  },
  {
    path: "/devices/view-device",
    name: "View Register Devices",
    component: ViewDevices,
    exact: true,
  },
  {
    path: "/affiliate-marketing",
    name: "Affiliate Marketing",
    component: AllAffiliate,
    exact: true,
  },
  {
    path: "/technician",
    name: "Technician",
    component: Technician,
    exact: true,
  },
  {
    path: "/landlord",
    name: "Landlord",
    component: AllLandlord,
    exact: true,
  },
  {
    path: "/user-account",
    name: "User Account",
    component: AllAccount,
    exact: true,
  },
  {
    path: "/user-account/register-user",
    name: "Register User",
    component: RegisterUser,
    exact: true,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: EditPassword,
    exact: true,
  },
  {
    path: "/user-account/edit-user",
    name: "Edit User",
    component: EditUsers,
    exact: true,
  },
  {
    path: "/user-account/view-user",
    name: "View User",
    component: ViewUsers,
    exact: true,
  },
  // {
  //   path: "/checklist-inspection",
  //   name: "Checklist Inspection",
  //   component: ChecklistInspection,
  //   exact: true,
  // },
  {
    path: "/master-checklist",
    name: "Master Checklist Inspection",
    component: MasterChecklistInspection,
    exact: true,
  },
  {
    path: "/master-checklist/add",
    name: "Master Checklist Inspection Add",
    component: MasterChecklistInspectionAdd,
    exact: true,
  },
  {
    path: "/master-checklist/edit",
    name: "Master Checklist Inspection Edit",
    component: MasterChecklistInspectionEdit,
    exact: true,
  },
  {
    path: "/master-checklist/view",
    name: "Master Checklist Inspection View",
    component: ViewChecklist,
    exact: true,
  },
  {
    path: "/list-inspection",
    name: "Master Inspection",
    component: MasterInspection,
    exact: true,
  },
  {
    path: "/list-inspection/add",
    name: "Master Inspection Add",
    component: MasterInspectionAdd,
    exact: true,
  },
  {
    path: "/survey",
    name: "Survey",
    component: MasterSurvey,
    exact: true,
  },
  {
    path: "/survey/view",
    name: "View Survey",
    component: MasterSurveyView,
    exact: true,
  },
  {
    path: "/survey/edit",
    name: "Edit Survey",
    component: MasterSurveyEdit,
    exact: true,
  },
  {
    path: "/survey/view/change-address",
    name: "View Survey Change Address",
    component: ChangeAddressFromView,
    exact: true,
  },
  {
    path: "/survey/edit/change-address",
    name: "Edit Survey Change Address",
    component: ChangeAddressFromEdit,
    exact: true,
  },
  {
    path: "/survey/add",
    name: "Add Survey",
    component: MasterSurveyAdd,
    exact: true,
  },
  {
    path: "/installation",
    name: "Installation",
    component: Installation,
    exact: true,
  },
  {
    path: "/installation/add",
    name: "Installation Add",
    component: InstallationAdd,
    exact: true,
  },
  {
    path: "/installation/view",
    name: "Installation View",
    component: InstallationView,
    exact: true,
  },
  {
    path: "/installation/edit",
    name: "Installation Edit",
    component: InstallationEdit,
    exact: true,
  },
  {
    path: "/installation/submit",
    name: "Installation Submit",
    component: SubmitInstallase,
    exact: true,
  },
  {
    path: "/crypto-wallet",
    name: "Crypto Wallet",
    component: CryptoWallet,
    exact: true,
  },
  {
    path: "/withdraw-request",
    name: "Withdraw Request",
    component: WithdrawRequest,
    exact: true,
  },
  {
    path: "/withdraw-history",
    name: "Withdraw History",
    component: WithdrawHistory,
    exact: true,
  },
  {
    path: "/mining-history",
    name: "Mining History",
    component: MinningHistory,
    exact: true,
  },
  {
    path: "/wallet-history",
    name: "Wallet History",
    component: WalletHistory,
    exact: true,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    exact: true,
  },
  {
    path: "/maintenance/add",
    name: "Maintenance Add",
    component: AddMaintenance,
    exact: true,
  },
  {
    path: "/maintenance/edit",
    name: "Maintenance Edit",
    component: EditMaintenance,
    exact: true,
  },
  {
    path: "/maintenance/submit",
    name: "Maintenance submit",
    component: SubmitMaintenance,
    exact: true,
  },
  {
    path: "/maintenance/view-maintenance",
    name: "Maintenance submit",
    component: ViewMaintenance,
    exact: true,
  },
  {
    path: "/surveyor",
    name: "Surveyor",
    component: Surveyor,
    exact: true,
  },
  {
    path: "/surveyor/submit",
    name: "Surveyor Submit",
    component: SubmitSurveyor,
    exact: true,
  },
  {
    path: "/surveyor/view",
    name: "Surveyor View",
    component: ViewSurveyor,
    exact: true,
  },
];

export default routes;
