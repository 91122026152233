import { message } from "antd";
import api from "config/axiosConfig";

const _apiAm = {
  getAm: async ({ setLoading = (e) => e, data, params }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/am/list",
        params,
        data,
      });

      setLoading(false);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      return errMsg;
    }
  },
  postAm: async ({
    setLoading = (e) => e,
    data = {
      ktp: undefined,
      name: undefined,
      email: undefined,
      phone: undefined,
      address: undefined,
      id_card: undefined,
      affiliate_referance_id: undefined,
    },
    params,
  }) => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("ktp", data.ktp);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("address", data.address);
      formData.append("id_card", data.id_card);
      if (data.affiliate_referance_id) {
        formData.append("affiliate_referance_id", data.affiliate_referance_id);
      }

      let _api = await api({
        url: "/users/am/register?is_web=1",
        method: "POST",
        data: formData,
        params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // setLoading(false);
      message.success(_api.data.message);
      setTimeout(() => {
        window.location.assign(
          window.history.state == "" ? "/user-account" : window.history.state
        );
      }, 1500);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error?.response?.status === 401) {
        message.error(error.response.data.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/login");
        }, 1500);
      }
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg);
      return errMsg;
    }
  },
  editAm: async ({ setLoading = (e) => e, data, params, id }) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("ktp", data.ktp);
    formData.append("name", data.name);
    // formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("id_card", data.id_card);
    formData.append("status", data.status);

    try {
      let _api = await api({
        url: "/users/am/edit/" + id,
        method: "PATCH",
        data: formData,

        params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setTimeout(() => {
        window.location.assign(
          window.history.state == "" ? "/user-account" : window.history.state
        );
      }, 1500);
      message.success(_api.data.message);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error?.response?.status === 401) {
        message.error(error.response.data.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/login");
        }, 1500);
      }
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg[0]);
      return errMsg;
    }
  },
  deleteAm: async ({ setLoading = (e) => e, params, id }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/am/delete/" + id,
        method: "PATCH",
        params,
      });

      setLoading(false);
      message.success(_api.data.message);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error?.response?.status === 401) {
        message.error(error.response.data.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/login");
        }, 1500);
      }
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg[0]);
      return errMsg;
    }
  },
  editAm2: async ({
    setLoading = (e) => e,
    data = {
      ktp: undefined,
      name: undefined,
      phone: undefined,
      address: undefined,
      id_card: undefined,
      affiliate_referance_id: undefined,
    },
    params,
    id,
  }) => {
    setLoading(true);

    try {
      let formData = new FormData();
      formData.append("ktp", data.ktp);
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("address", data.address);
      formData.append("id_card", data.id_card);
      formData.append("affiliate_referance_id", data.affiliate_referance_id);

      let _api = await api({
        url: "/users/am/edit/" + id,
        method: "PATCH",
        data: formData,
        params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // setTimeout(() => {
      //   window.location.assign(
      //     window.history.state == "" ? "/user-account" : window.history.state
      //   );
      // }, 1500);
      message.success(_api.data.message);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error?.response?.status === 401) {
        message.error(error.response.data.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/login");
        }, 1500);
      }
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg[0]);
      return errMsg;
    }
  },
  approve: async ({ setLoading = (e) => e, id, status }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/am/edit/" + id,
        method: "PATCH",
        data: {
          status,
        },
      });

      message.success(_api.data.message);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error?.response?.status === 401) {
        message.error(error.response.data.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/login");
        }, 1500);
      }
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg[0]);
      return errMsg;
    }
  },
  getAmbyReff: async ({ setLoading = (e) => e, data, params, reff }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/am/find/" + reff,
        params,
        data,
      });

      setLoading(false);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg[0]);
      return errMsg;
    }
  },
  postAmByReff: async ({
    setLoading = (e) => e,
    data = {
      ktp: undefined,
      name: undefined,
      email: undefined,
      phone: undefined,
      address: undefined,
      id_card: undefined,
    },
    params,
  }) => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("ktp", data.ktp);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("address", data.address);
      formData.append("id_card", data.id_card);

      let _api = await api({
        url: "/users/am/register?is_web=1",
        method: "POST",
        data: formData,
        params,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // setLoading(false);
      message.success(_api.data.message);
      setTimeout(() => {
        window.close();
        window.location.replace("/login");
      }, 1500);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error?.response?.status === 401) {
        message.error(error.response.data.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.replace("/login");
        }, 1500);
      }
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      message.error(errMsg);
      return errMsg;
    }
  },
  getWalletHistory: async ({ setLoading = (e) => e, data, params, am_id }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/grintech/wallet/am/history/" + am_id,
        params,
        data,
      });

      setLoading(false);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      return errMsg;
    }
  },
  getWalletHistoryV2: async ({ setLoading = (e) => e, data, params }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/wallet/mutasi/v1/am",
        params,
        data,
      });

      setLoading(false);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      return errMsg;
    }
  },
  getListTree: async ({ setLoading = (e) => e, data, params, am_id }) => {
    setLoading(true);
    try {
      let _api = await api({
        url: "/users/am/list/tree/" + am_id,
        params,
        data,
      });

      setLoading(false);
      return _api.data;
    } catch (error) {
      let errMsg = [];
      if (error.response) {
        errMsg = [
          error.response.data.message,
          " ",
          error.response.data.responseCode,
        ];
      } else {
        errMsg = [error.message, " ", 500];
      }
      // message.error(errMsg[0]);
      return errMsg;
    }
  },
};

export { _apiAm };
