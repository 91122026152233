import React from "react";
import { Form, Input, Button, Typography, Row, Col, Result } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Logo from "../../assets/GRINTECH_Reverse_01_full-removebg2.png";
import query from "query-string";
import { _apiAccount } from "actions/auth";

const RegisterVerify = (props) => {
  const [loading, setLoading] = React.useState(false);

  const urlData = query.parse(window.location.search);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    _apiAccount.regVerify({
      setLoading,
      base64: urlData.u,
      pw: values.password,
    });
  };

  //   React.useEffect(() => {
  //     if (authState.auth.logined && !loading) {
  //       setTimeout(() => {
  //         window.location.replace("/");
  //       }, 1000);
  //     }
  //   }, [authState, loading]);
  return (
    <div>
      {urlData.m && urlData.u ? (
        <Row>
          <Col
            xl={24}
            sm={24}
            xs={24}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "240px 0",
              height: "100%",
            }}
          >
            <Form
              name="normal_login"
              className="login-form"
              // initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              layout="vertical"
            >
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "36px",
                  fontSize: "24px",
                }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ position: "relative", width: "80%" }}
                />
                <Typography.Title
                  level={4}
                  style={{ marginTop: "14px", fontWeight: "bolder" }}
                >
                  Verify Account
                </Typography.Title>
              </div>
              <Form.Item
                name="email"
                // initialValue={localStorage.getItem("username")}
                initialValue={urlData.m}
                rules={[{ message: "Please input your Email!" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    type: "string",
                    min: 6,
                    message: "Password must be at least 6 characters!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ marginTop: "12px" }}
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist or invalid url."
          extra={
            <Button type="primary" onClick={() => window.location.replace("/")}>
              Back Home
            </Button>
          }
        />
      )}
    </div>
  );
};

export default RegisterVerify;
