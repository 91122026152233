import React from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Result,
  Spin,
  Divider,
  message,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Logo from "../../assets/GRINTECH_Reverse_01_full-removebg2.png";
import query from "query-string";
import { _apiAccount } from "actions/auth";
import { _apiAm } from "actions/am";
import { PhoneInputAntd } from "config/config.phone";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.file;
};

const ForgotPasswordVerify = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [img, setImg] = React.useState("");
  const [loadingimg, setloadingimg] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const urlData = query.parse(window.location.search);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    _apiAm.postAmByReff({
      setLoading,
      data: {
        ktp: values.idcardimg.originFileObj,
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: values.address,
        id_card: values.idcard,
      },
    });
  };

  React.useEffect(() => {
    if (urlData.reff && urlData.reff.length > 2) {
      _apiAm
        .getAmbyReff({
          setLoading,
          reff: urlData.reff,
        })
        .then((ok) => {
          // console.log(ok);
          if (ok.data) {
            setOpen(true);
            form.setFields([
              {
                name: "am_name",
                value: ok.data.name,
              },
            ]);
          } else {
            setOpen(false);
          }
        });
    }
  }, []);

  const handleChangeimg = (info) => {
    if (info.file.status === "uploading") {
      setloadingimg(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setloadingimg(false);
        setImg(imageUrl);
      });
    }
  };

  //   React.useEffect(() => {
  //     if (authState.auth.logined && !loading) {
  //       setTimeout(() => {
  //         window.location.replace("/");
  //       }, 1000);
  //     }
  //   }, [authState, loading]);
  const uploadButton = (
    <div>
      {loadingimg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <div>
      {urlData.reff && urlData.reff.length > 2 && open ? (
        <Row>
          <Col
            xl={24}
            sm={24}
            xs={24}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "32px 0",
              height: "100%",
            }}
          >
            <Form
              form={form}
              name="ref-am-reg"
              className="ref-am-form"
              // initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "36px",
                }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ position: "relative", width: "50%" }}
                />
                <Typography.Title
                  level={4}
                  style={{ marginTop: "14px", fontWeight: "bolder" }}
                >
                  Affiliate Marketing Registration
                </Typography.Title>
              </div>
              <Form.Item
                name="am_name"
                label="Affiliate Reference"
                // initialValue={localStorage.getItem("username")}
                rules={[{ message: "Please input your Affiliate Reference!" }]}
              >
                <Input placeholder="Affiliate Reference" readOnly />
              </Form.Item>
              <Divider />
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please Input your Name" }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                // initialValue={localStorage.getItem("username")}
                initialValue={urlData.m}
                rules={[
                  { message: "Please input your Email!", required: true },
                  {
                    type: "email",
                    message: "Seems not like Email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  { required: true, message: "Please Input your Phone!" },
                ]}
              >
                <PhoneInputAntd placeholder="Phone" />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please Input your Address!" },
                ]}
              >
                <Input.TextArea placeholder="Address" />
              </Form.Item>
              <Form.Item
                name="idcard"
                label="ID Card"
                rules={[
                  {
                    required: true,
                    message: "Please Input your ID Card Number!",
                  },
                ]}
              >
                <Input placeholder="ID Card" />
              </Form.Item>
              <Form.Item
                name="idcardimg"
                valuePropName="file"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Please Upload your ID Card Photo!",
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeimg}
                >
                  {img ? (
                    <img src={img} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: "12px" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist or invalid url."
          extra={
            <Button type="primary" onClick={() => window.close()}>
              Close
            </Button>
          }
        />
      )}
    </div>
  );
};

export default ForgotPasswordVerify;
